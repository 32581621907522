<template>
  <div class="container">
    <div class="page-title">
      <el-row>
        <el-col :span="18">
          <h3>软件类别管理
            <el-divider direction="vertical"></el-divider>
            <span @click="$router.go(-1)">返回</span>
          </h3>
        </el-col>
        <el-col :span="6" style="text-align: right;">
          <el-button type="text" icon="el-icon-plus" @click="addCate">新增软件类别</el-button>
        </el-col>
      </el-row>

    </div>
    <div class="query-box">
      <el-form :inline="true" :model="queryParams" @submit.native.prevent>
        <el-form-item label="软件类别" style="margin-bottom: 0">
          <el-input placeholder="输入软件类别名称" v-model="queryParams.name" clearable style="width: 200px;">
          </el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 0">
          <el-button type="primary" native-type="submit" @click="searchData"><i class="el-icon-search"></i> 查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" ref="multipleTable" v-loading="loading" element-loading-text="加载中..." style="width: 100%">
      <el-table-column label="类别名称" prop="name">
      </el-table-column>
      <el-table-column label="排序" prop="sort">
      </el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <span class="row-opt" @click="editCate(scope.$index, scope.row)"><el-link type="primary" icon="el-icon-edit-outline">修改</el-link></span>
          <el-divider direction="vertical"></el-divider>
          <span class="row-opt" @click="deleteData(scope.row)"><el-link type="primary" icon="el-icon-delete">删除</el-link></span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagenation">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
      </el-pagination>
    </div>

    <el-dialog :title="popTitle" :visible.sync="addPop" :close-on-click-modal="false" width="520px">
      <el-form :model="form" :rules="rules" ref="appForm" label-width="140px" @submit.prevent="submit('appForm')">
        <el-form-item label="软件类别名称" prop="name">
          <el-input v-model="form.name" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="form.sort" type="number" placeholder="数字越大越靠前" style="width: 160px"></el-input>
        </el-form-item>
        <div style="margin-top:30px; text-align: center">
          <el-button type="primary" native-type="submit" @click.prevent="submit('appForm')">提交</el-button>
          <el-button @click="closePop">取消</el-button>
        </div>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
  export default {
    name: "SoftType",
    data() {
      return {
        queryParams: {
          name: ''
        },
        loading: true,
        tableData: [],
        currentPage: 1,
        pageSize: 10,
        total: 0,
        multipleSelection: [],
        addPop: false,
        popTitle: '新增软件类别',
        mode: 'add',
        form: {
          name: '',
          sort: ''
        },
        rules: {
          name: [
            {required: true, message: '名称不能为空', trigger: 'blur'},
            {min: 2, max: 40, message: '长度2-40字符', trigger: 'blur'}
          ],
          sort: [
            {required: true, message: '排序不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    created() {
      this.getListData();
    },
    methods: {
      getListData () {
        this.queryParams.page = this.currentPage;
        this.queryParams.limit = this.pageSize;
        this.$get(this.$api.GetSoftTypeList, this.queryParams).then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total_count;
        });
      },
      searchData() {
        this.loading = true;
        this.currentPage = 1;
        this.tableData = [];
        this.total = 0;
        this.getListData();
      },
      handleCurrentChange (val) {
        this.currentPage = val
        this.getListData()
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.getListData()
      },
      //删除列表选项
      deleteData(row) {
        this.$confirm('此操作将删除所选项, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$post(this.$api.DeleteSoftType, {id: row.id})
              .then(() => {
                this.$message({
                  message: '操作成功！',
                  type: 'success'
                });
                this.getListData();
              });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      addCate() {
        this.addPop = true;
        this.popTitle = '新增软件类别';
        this.mode = 'add';
        this.form = {};
      },
      editCate (index, row) {
        this.$get(this.$api.GetSoftTypeInfo, {id: row.id}).then((res) => {
          this.addPop = true;
          this.popTitle = '编辑软件类别';
          this.mode = 'edit';
          this.form = res.data;
        });
      },
      closePop() {
        this.addPop = false;
        this.form = {};
        this.popTitle = '新增软件类别';
        this.mode = 'add';
        this.getListData();
      },
      submit (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let postUrl = this.$api.AddSoftType;
            if (this.mode == 'edit') {
              postUrl = this.$api.EditSoftType;
            }
            this.$post(postUrl, this.form).then(() => {
              this.$message({
                message: '操作成功',
                type: 'success'
              });
              this.closePop();
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
    }
  }
</script>

<style scoped lang="less">
  .query-box {
    background: #f6f6f6;
    padding: 10px;
    margin: 10px 0;
  }
  .pagenation {
    margin: 20px auto;
    text-align: center;
  }
</style>
